import React from 'react'

const FormContext = React.createContext()

const useFormContext = ()=>{
    const context = React.useContext(FormContext)
    
    if(!context) throw new Error("Form sub-components can not be used outside a form component")

    return context 
}

export {FormContext}
export default useFormContext