import React from "react"
import styled from "styled-components"
import Form from "../components/form/Form"
import breakpoints from "../styles/breakpoints"

const StyledContactPage = styled.div``

const ContactForm = styled(Form)`
    margin-top: 0.5rem;

    @media(${breakpoints.tabletUp}){
        margin-top: 2rem;
    };
` 

const ContactPage = () => {

    return (
            <StyledContactPage>
                <ContactForm action={`https://formspree.io/${process.env.GATSBY_FORMSPREE_ID}`} method="POST">
                    <Form.Input
                        label="Name"
                        type="text"
                        name="name"
                        validate= {{
                            required:'Name is a required field!'
                        }}
                    />
                     <Form.Input
                        label="Email"
                        type="email"
                        name="email"
                        validate={{
                            required:"Email field is required!",
                            pattern:{
                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Please provide a valid email address (e.g someone@something.com)'
                            }
                        }}
                    />
                    <Form.Input
                        label="Subject"
                        type="text"
                        name="subject"
                        validate= {{
                            required:'Subject is a required field!'
                        }}
                    />
                    <Form.Input
                        type="textarea"
                        label="Message"
                        name="message"
                    />                   
                    <Form.Input className="notranslate"
                        type="submit"
                        value="Submit"
                        name="submit"                        
                    />
                </ContactForm>
            </StyledContactPage>
    )
}

export default ContactPage
