import React from 'react'
import styled from 'styled-components'
import colors from '../../styles/colors'
import FormInput from './FormInput'
import PropTypes from 'prop-types'
import { FormContext } from './useFormContext'
import stringifyFormData from '../../helpers/stringifyFormData'
import Alert from '../Alert'
import Spinner from '../Spinner'

const StyledForm = styled.form`
    padding:1rem;
    display:flex;
    flex-direction: column;
    gap:1rem;
    max-width:600px;
    background: ${colors.secondary}22;
    border-radius: 3px;
    margin: auto;
`

const Form = ({ children, onSubmit, action, method, className, style }) => {

    const fieldsTemplate = React.useCallback(() => {
        const fieldsTemplate = {}
        React.Children.forEach(children, inputField => {
            fieldsTemplate[inputField.props.name] =
            {
                value: '',
                type: inputField.props.type,
                validate: inputField.props.validate || null,
                errors: [],
                touched: false
            }
        })
        return fieldsTemplate
    }, [children])

    //stateful object with fields' name as keys and objects with properties as values
    const [fields, setFields] = React.useState(fieldsTemplate)
    const [typingTimeoutId, setTypingTimeoutId] = React.useState(null)
    const [submissionAlert, setSubmissionAlert] = React.useState(null)
    const [submitting, setSubmitting] = React.useState(false)

    const handleUserInput = (e) => {
        const value = e.target.value
        const fieldName = e.target.name

        setFields((fields) => {
            return {
                ...fields,
                [fieldName]: {
                    ...fields[fieldName],
                    value: value
                }
            }
        })

        if (typingTimeoutId) clearTimeout(typingTimeoutId)

        //if this field has no validation props skip validation process entirely
        if (!fields[fieldName].validate) return

        //reminder: setTimeout returns a number representing id of the timer
        setTypingTimeoutId(setTimeout(() => {
            const errors = validate(fields[fieldName].validate, value)

            setFields((fields) => {
                return {
                    ...fields,
                    [fieldName]: {
                        ...fields[fieldName],
                        errors: errors
                    }
                }
            })

            setTypingTimeoutId(null)
        }, 400))
    }



    const handleSubmit = (e) => {
        e.preventDefault()

        let allFieldsValid = true

        for (let fieldName of Object.keys(fields)) {
            const currentField = fields[fieldName];

            //if the field has already been used and has errors(been validated)
            //or if the field has no validation props, skip validation entirely
            if (currentField.errors.length || !currentField.validate) continue

            const errors = validate(fields[fieldName].validate, fields[fieldName].value)
            if (errors.length) allFieldsValid = false

            setFields((fields) => {
                return {
                    ...fields,
                    [fieldName]: {
                        ...fields[fieldName],
                        errors: errors
                    }
                }
            })
        }

        if (allFieldsValid) {
            setSubmitting(true)
            if (onSubmit) onSubmit(e)
            else {
                setSubmissionAlert({ message: <Spinner />, color: 'transparent' })

                fetch(action, {
                    method: method || "POST",
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    referrerPolicy: 'no-referrer',
                    body: stringifyFormData(new FormData(e.target))
                })
                    .catch(e => console.log(e))
                    .then(httpRes => httpRes.json())
                    .then(response => {
                        if (response.ok) {
                            setSubmissionAlert({ message: "Thank you!", color: 'success' })
                            resetFields();
                        }
                        else
                            setSubmissionAlert({ message: "Something went wrong on our side, please try again.", color: 'danger' })
                    })
                    .finally(
                        setSubmitting(false)
                    )
                // setTimeout(() => {
                //     const res = { ok: false }
                //     if (res.ok) {
                //         setSubmissionAlert({ message: "Thank you!", color: 'success' });
                //         resetFields();
                //     }
                //     else {
                //         setSubmissionAlert({ message: "Something went wrong on our side, please try again.", color: 'danger' })
                //     }
                //     setSubmitting(false)
                // }, 2000)
            }
        }
    }

    const resetFields = () => setFields(fieldsTemplate)
    const resetAlert = () => setSubmissionAlert(null)

    const validate = (validationParams, value) => {
        let errors = []

        if (validationParams.required && !value) { //if field required and value is empty
            errors.push(validationParams.required)// if required isnt empty, it contains the error message            
        }
        else if (validationParams.pattern && value) {
            if (!value.match(validationParams.pattern.value)) errors.push(validationParams.pattern.message)
        }
        return errors
    }

    const moddedChildren = React.Children.map(children, inputField => {
        if (inputField.props.type === 'submit') {
            return React.cloneElement(inputField, {
                disabled: submitting
            })
        }
        else {
            return React.cloneElement(inputField, {
                value: fields[inputField.props.name].value,
                onChange: (e) => handleUserInput(e),
                errors: fields[inputField.props.name].errors
            })
        }
    })

    return (
        <StyledForm style={style} className={className} noValidate onSubmit={handleSubmit}>
            <FormContext.Provider value={{}}>
                {moddedChildren}
            </FormContext.Provider>
            {submissionAlert && <Alert {...submissionAlert} timer={1300} onTimerEnd={() => resetAlert()} />}
        </StyledForm>
    )
}

Form.propTypes = {
    onSubmit: PropTypes.func,
    style: PropTypes.object,
    children: (props, propName, componentName) => {
        const children = props[propName];
        const requiredChildType = 'FormInput'

        React.Children.forEach(children, function (child) {
            if (child.type.displayName !== requiredChildType) {
                return new Error(
                    `${componentName} only accepts children of type ${requiredChildType}.`
                );
            }
        });
    }
}


Form.Input = FormInput
export default Form
