import React from 'react'
import styled from 'styled-components'
import CloseButton from './CloseButton'
import colors from '../styles/colors'

const AlertContainer = styled.div`
    z-index: 999;
    display: ${props => props.$visible ? 'block' : 'none'};
    position:fixed;
    border-radius:0.25rem;
    border: 1px solid;
    border-color: ${props=>colors[props.$color]};   
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    background-color: ${props=>colors[props.$color+'Light']}aa;

    transition: all 0.3s ease-in;
    color: ${props => colors[props.$color]};
    max-height: 200px;
    max-width: 400px;
`

const Message = styled.div`
    padding: 3rem;
    user-select:none;
`

const Alert = ({ title, message, color, timer, onTimerEnd }) => {

    const [alertVisible, setAlertVisible] = React.useState(true)

    const closeAlert = (e) => {
        setAlertVisible(false)
    }

    React.useEffect(() => {
        timer && setTimeout(() => {        
            setAlertVisible(false)
            onTimerEnd && onTimerEnd()
        }, timer)
    }, [timer,onTimerEnd])

    return (
        <AlertContainer $visible={alertVisible} $color={color}>
            <Message>
                {message}
            </Message>
            <CloseButton color={color} size='small' onClick={closeAlert}></CloseButton>
        </AlertContainer>
    )
}

export default Alert
