

//Converts formData into plain object and then stringifies it
const stringifyFormData = (formData)=>{

    let jsonObj = {}

    for(let [key,value] of formData) {
        jsonObj[key] = value
    }
    return JSON.stringify(jsonObj)
}

export default stringifyFormData