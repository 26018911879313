import React from 'react'
import styled, { css } from 'styled-components'
import cancelIcon from '../../assets/IconPack/svg/cancel.svg'
import colors from '../styles/colors'

const StyledCloseButton = styled.button`
    mask: url(${cancelIcon});

    background-color: ${props => colors[props.color]};

    ${props => {
        switch (props.size) {
            case 'small': return css`
                height: 25px;
                width: 25px;
            `
            case 'medium': return css`
                height: 35px;
                width: 35px;
            `
            case 'large': return css`
                height: 60px;
                width: 60px;
            `
            default: return css`
                height: 35px;
                width: 35px;
            `
        }
    }};
    position: absolute;
    top:9px;
    right:10px;
    
    cursor: pointer;
    &:hover{
        background-color: ${props => colors[props.color]};
    }
`

const CloseButton = (props) => {
    return <StyledCloseButton {...props} />
}

export default CloseButton
