import React from 'react'
import styled from 'styled-components'
import Button from '../Button'
import typography from '../../styles/typography'
import colors from '../../styles/colors'
import useFormContext from './useFormContext'

const StyledInput = styled.input`
    background: transparent;
    border:0;
    outline: 0;
    padding: 0;
    width:100%;
    z-index:2;
    padding-top:25px;

    &:focus{
        border-radius: 3px;
    }
`

const FormInputContainer = styled.div`
    &+&{margin-top: 1rem;}
    position: relative;
    width:100%;
    border-bottom: ${props => props.$type !== 'submit' && `1px solid ${colors.secondary};`};
    min-height:50px;
    display: flex;
    transition: all 0.3s linear;
    font-family: ${typography.fonts.primary};

    &::before{
        font-size: ${props => props.$inputFocused || props.$inputDirty ? 'smaller' : 'initial'};
        color: ${props => props.$inputFocused || props.$inputDirty ? colors.primary : colors.secondary};
        content: '${props => props.$label}';
        position:absolute;
        left:0;
        top:${props => props.$inputFocused || props.$inputDirty ? '0;' : '25px;'};
        height:100%;
        width:100%;
        z-index:-2;
        transition: all 0.3s linear;
    }

    &::after{
        content: "";
        position: absolute;
        left:0;
        bottom:0;
        width: ${props => props.$inputFocused || props.$errors.length ? '100%' : '0'};
        height: 2px;
        border-bottom: 1px solid;
        border-color: ${props => props.$errors.length ? 'red' : 'black'};
        transition: width 0.3s linear;
    }
`

const FieldInfo = styled.div`
    padding:0;
    color: red;
    font-size:smaller;
    margin-top: -10px;
    justify-content:space-between;
    position: relative;
`

const StyledTextArea = styled(StyledInput).attrs(props => ({ as: "textarea" }))`
    width:100%;
    min-height: 10rem;
`

const FormInput = ({ label = "", htmlFor, onBlur, onFocus, type, value, children, errors = [], validate={}, ...rest }) => {

   useFormContext()

    const [inputFocused, setInputFocused] = React.useState(false);
    const [inputDirty, setInputDirty] = React.useState(false);

    const handleBlur = (e) => {
        onBlur && onBlur(e);
        setInputFocused(false);
    }

    const handleFocus = (e) => {
        onFocus && onFocus(e);
        setInputFocused(true);
    }

    React.useEffect(() => {
        value ? setInputDirty(true) : setInputDirty(false)
    }, [value])


    const elementToRender = (() => {
        switch (type) { 
            case "textarea": return <StyledTextArea value={value} onFocus={handleFocus} onBlur={handleBlur} {...rest}>{children}</StyledTextArea>;
            case "submit": return <Button as="input" type={type} value={value} className="notranslate" {...rest}>{children}</Button>;
            default: return <StyledInput value={value} type={type} onFocus={handleFocus} onBlur={handleBlur} {...rest}>{children}</StyledInput>;
        }
    })()

    return (
        <>
            <FormInputContainer validate={validate} $inputFocused={inputFocused} $errors={errors} $inputDirty={inputDirty} $label={label} $type={type}>
                {elementToRender}
            </FormInputContainer>
            <FieldInfo>
                <div>{errors}</div>
                {
                    validate.required && <div style={{position:'absolute',right:'0', color:colors.secondary}}>*required</div>
                }
            </FieldInfo>
        </>
    )
}

FormInput.displayName = 'FormInput'

export default FormInput
